<template>
    <div style="background-color: white; width: 100%;">
        gs
    </div>
</template>

<script>
export default {
    components: {
        
    },
    data() {
        return {
            content: []
        }
    },
    created() {
        this.eventBus.off('updateContent')
        this.eventBus.on('updateContent', (data) => {
            console.log(data);
        })
    }
};
</script>

<style>

</style>