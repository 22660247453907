<template>
    <div 
        class="doc_area"
        style="
            background-color: white;
            width: 100%;
            height: 100vh;
            padding: 20px 0
        "    
    >
        <div
            v-for="items, index in this.documentation"
            :key="items.id"
            class="doc_block"
            @keydown="action($event, index)"
            @input="editBlock($event, index)"
            @mouseover="items.showMenu = true"
            @mouseout="items.showMenu = false"
            :style="items.showMenu === true ? 'background-color: #f3f3f9; border-radius: 20px':''"
            @contextmenu.prevent="openContextMenu($event, items)"
        >
            <!-- Область в котру ми можемо кидати строки, міняючи їх місцями -->
            <div 
                class="dragArea" 
            >
                
            </div>

            <div
                :style="items.showMenu === true ? 'visibility: visible' : 'visibility: hidden'"
                class="doc_block__contextMenu"
                style="margin-left: 10px"
            >
                <i 
                    class='bx bx-plus' 
                    @click="addBlock(index)" 
                    style="font-size: 16px; cursor: pointer"
                ></i>
                <i 
                    class='bx bx-menu' 
                    style="font-size: 16px; cursor: grab"
                ></i>
            </div>
            <div 
                class="doc_block__content"
                contenteditable="true"
                v-html="prepareRender(items)"
            >
            </div>
        </div>
    </div>

    <!-- Контекстне меню -->
    <my-context-menu 
        ref="contextMenu" 
        :key="this.contextMenuKey"
        :btn="btnContextMenu"
        @select="switchCntMenu"
    />
</template>

<script>
import MyContextMenu from '@/components/contextMenu/index.vue'; // компонент контекст-меню
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            contextMenuKey: 0,
            btnContextMenu: [ // Параметри для контекстного меню
                { 
                    title: this.$t('TurnInto'),
                    icon: 'bx bx-dots-vertical',
                    color: 'warning',
                    value: 'turnInto',
                    status: true,
                    perms: null,
                    isOpen: false,
                    children: [
                        {
                            title: 'Text',
                            icon: 'ri-paragraph',
                            color: '',
                            value: ['turnInto', 'p'],
                            status: true,
                            perms: null,
                        },
                        {
                            title: 'Heading 1',
                            icon: 'ri-h-1',
                            color: '',
                            value: ['turnInto', 'h1'],
                            status: true,
                            perms: null,
                        },
                        {
                            title: 'Heading 2',
                            icon: 'ri-h-2',
                            color: '',
                            value: ['turnInto', 'h2'],
                            status: true,
                            perms: null,
                        },
                        {
                            title: 'Heading 3',
                            icon: 'ri-h-3',
                            color: '',
                            value: ['turnInto', 'h3'],
                            status: true,
                            perms: null,
                        },
                        {
                            title: 'Heading 4',
                            icon: 'ri-h-4',
                            color: '',
                            value: ['turnInto', 'h4'],
                            status: true,
                            perms: null,
                        },
                        {
                            title: 'Heading 5',
                            icon: 'ri-h-5',
                            color: '',
                            value: ['turnInto', 'h5'],
                            status: true,
                            perms: null,
                        },
                        {
                            title: 'Heading 6',
                            icon: 'ri-h-6',
                            color: '',
                            value: ['turnInto', 'h6'],
                            status: true,
                            perms: null,
                        },
                    ],
                },
                { 
                    title: this.$t('Duplicate'),
                    icon: 'bx bx-copy',
                    color: 'info',
                    value: 'open',
                    status: true,
                    perms: null
                },
                { 
                    title: this.$t('Delete'),
                    icon: 'bx bx-trash',
                    color: 'danger',
                    value: 'open',
                    status: true,
                    perms: null
                },
            ],
            content: [],
            blocks: [
                {
                    tag: 'h1',
                    fontSize: '32px'
                },
                {
                    tag: 'h2',
                    fontSize: '26px'
                },
                {
                    tag: 'h3',
                    fontSize: '22px'
                },
                {
                    tag: 'h4',
                    fontSize: '20px'
                },
                {
                    tag: 'h5',
                    fontSize: '16px'
                },
                {
                    tag: 'h6',
                    fontSize: '12px'
                },
                {
                    tag: 'p',
                    fontSize: '12px'
                },
            ],
            documentation: [
                {
                    tag: 'p',
                    class: '',
                    style: 'font-size: 12px',
                    text: 'This is a paragraph of text.',
                    showMenu: false,
                    id: uuidv4()
                },
                {
                    tag: 'h1',
                    class: '',
                    style: 'font-size: 32px',
                    text: 'This is a heading.',
                    showMenu: false,
                    id: uuidv4()
                },
            ], // вся документація - це масив з різних блоків
            event: '', // Змінна, що є буфером для орієнтування в нашому документі
            index: '',
            tip: {
                coordX: '',
                coordY: '',
                tipPart: []
            } // координати для підказки
        }
    },
    components: {
        MyContextMenu
    },
    created() {
        /* 
            Створюємо фейковий масив, саме він буде зберігати динамічно наші тексти
            Перед відправкою, ми зможемо то все зберегти, без зайвого ререндеру
        */
        this.documentation.forEach((el) => {
            this.content.push(el.text)
        })
    },
    mounted() {
        const container = document.querySelector('.doc_area');

        /* 
            Створюємо MutationObserver 
            Ця штука допомагає відслідковувати додавання нових div в контейнер
            Одразу дає можливість поставити фокус на новостворений блок
        */

        
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    if(mutation.removedNodes.length != 0) {
                        if(document.querySelectorAll('.doc_block__content')[this.index]) {
                            document.querySelectorAll('.doc_block__content')[this.index].focus()
                            this.setCursorPosition(
                                document.querySelectorAll('.doc_block__content')[this.index]
                            )
                        } else if (document.querySelectorAll('.doc_block__content')[this.index - 1]){
                            document.querySelectorAll('.doc_block__content')[this.index - 1].focus()
                            this.setCursorPosition(
                                document.querySelectorAll('.doc_block__content')[this.index - 1]
                            )
                        } else if (document.querySelectorAll('.doc_block__content')[this.index + 1]){
                            document.querySelectorAll('.doc_block__content')[this.index + 1].focus()
                            this.setCursorPosition(
                                document.querySelectorAll('.doc_block__content')[this.index + 1]
                            )
                        }
                    } else {
                        mutation.addedNodes.forEach((node) => {
                            if (node.tagName === 'DIV') {
                                document.querySelectorAll('.doc_block__content').forEach((el, index) => {
                                    if(el === this.event.srcElement) {
                                        document.querySelectorAll('.doc_block__content')[document.querySelectorAll('.doc_block__content').length - 1].focus()
                                        this.setCursorPosition(
                                            document.querySelectorAll('.doc_block')[document.querySelectorAll('.doc_block__content').length - 1]
                                        )
                                    }
                                })
                            }
                        });
                    }
                } else if(mutation.type === 'characterData') {
                    if(mutation.target.wholeText === "" && this.event.inputType != 'insertFromPaste') {
                        // Видалення блоку, коли змінюється текст
                        this.documentation.splice(this.index, 1)
                        this.content.splice(this.index, 1)
                    }
                }
            }
        });
        

        // Налаштування для відстеження змін
        observer.observe(container, {    
            childList: true,         // Відслідковуємо додавання/видалення дочірніх елементів
            subtree: true,           // Відслідковуємо зміни у всіх дочірніх елементах
            characterData: true,     // Відслідковуємо зміни в текстових вузлах
            attributes: true         // Відслідковуємо зміни атрибутів
        });
    },  
    watch: {
        content: {
            handler(newValue, oldValue) {
                console.log("Масив змінено:");
                console.log("Новий:", newValue);
                console.log("Старий:", oldValue);
            },
            deep: true, // Відстежуємо зміни всередині об'єктів масиву
        },
    },
    methods: {
        switchCntMenu(e, item){
            // Виконуємо обрану дію з контекстного меню
            /** 
             * @param {Array} e
             * @param {Object} item
            */
            
            if(e[0] === "turnInto") {
                const id = this.documentation.findIndex(el => el.id === item.id)
                this.documentation[id].tag = e[1]
                this.documentation[id].style = 'font-size ' + this.blocks.find(el => el.tag ===  e[1]).fontSize
            }
        },
        openContextMenu(event, item) {
            // Викликаємо метод показу контекстного меню після оновлення DOM
            this.$nextTick(() => {
                // Виклик методу показу контекстного меню у компоненті contextMenu,
                // передаючи подію та об'єкт item
                this.$refs.contextMenu.showContextMenu(event, item);
            });
        },
        addBlock(index) {
            let obj = {
                tag: 'p',
                class: '',
                style: '',
                text: 'Текст',
                id: uuidv4()
            }
            this.documentation.splice(index + 1, 0, obj)
            this.content.splice(index + 1, 0, obj.text)
        },
        action(event, index) {
            this.event = event // кидаємо 
            this.index = index
            // Якщо Enter, то додаємо новий блок
            if (event.key === 'Enter') {
                event.preventDefault(); // Зупиняємо стандартну дію (додання нового рядка)

                document.querySelectorAll('.doc_block__content').forEach((el, index) => {
                    if(el === event.srcElement) {
                        let obj = {
                            tag: 'p',
                            class: '',
                            style: 'color: gray',
                            text: 'Текст',
                            id: uuidv4()
                        }
                        this.documentation.splice(index + 1, 0, obj)
                        this.content.splice(index + 1, 0, obj.text)
                    }
                })
            } else if(event.key === 'ArrowDown') {
                if(document.querySelectorAll('.doc_block')[index + 1]) {
                    event.preventDefault()
                    document.querySelectorAll('.doc_block')[index + 1].focus()
                    this.setCursorPosition(
                        document.querySelectorAll('.doc_block')[index + 1]
                    )
                }
            } else if(event.key === 'ArrowUp') {
                event.preventDefault()
                if(document.querySelectorAll('.doc_block')[index - 1]) {
                    document.querySelectorAll('.doc_block')[index - 1].focus()
                    this.setCursorPosition(
                        document.querySelectorAll('.doc_block')[index - 1]
                    )
                }
            } else if(event.key === 'Tab') {
                event.preventDefault()
                // this.documentation[index].text = "\t" + this.documentation[index].text
            }
        },
        setCursorPosition(event) {
            const container = event; // Отримуємо блок, що отримав фокус
            const selection = window.getSelection();
            const range = document.createRange();

            // Знаходимо останній текстовий вузол
            let lastChild = container.lastChild;
            while (lastChild && lastChild.nodeType !== 3) {
                lastChild = lastChild.lastChild; // Рекурсивно шукаємо текстовий вузол
            }

            if (lastChild) {
                const textLength = lastChild.textContent.length;
                range.setStart(lastChild, textLength); // Встановлюємо курсор в кінець
                range.collapse(true);

                selection.removeAllRanges();
                selection.addRange(range);
            } else {
                console.error("Текстовий вузол не знайдено");
            }
        },
        // Змінюємо текст в блоці документації
        editBlock(event, index){
            this.event = event
            this.content[index] = event.target.innerText
            if(this.documentation[index].style === "color: gray") {
                this.documentation[index].style = ''
                this.documentation[index].text = event.target.innerText.replace("Текст", '')
                this.content[index] = event.target.innerText.replace("Текст", '')
            }

            const regex = "/:[a-zA-Z]+:(https?:\/\/[^\s]+)/g";
            console.log(this.content[index].match(regex)) // todo if
        },
        prepareRender(item) {
            if(item.tag != 'code' || item.tag != 'table') {
                return `<${item.tag} style='${item.style}; word-break:break-all'>${item.text}</${item.tag}>`
            } else {
                switch(item.tag) {
                    case 'code':

                    break;
                    case 'table': 
                    
                    break;
                }
            }
        }
    }
}
</script>

<style scoped>
    .doc_block{
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
    }
    .doc_block__content:focus-visible {
        outline: inherit;
    }
    .doc_block :is(h1, h2, h3, h4, h5, h6, p) {
        margin: 0;
        padding: 0;
    }
    .tooltipCustom {
        position: absolute;
    }
</style>