<template>
    <div class="row upMenu">
        <div class="col-3">
            <button type="button" class="btn btn-outline-dark">
                <i class='bx bx-arrow-back'></i>
                Назад
            </button>
        </div>
    </div>
    <div class="row workSpace">
        <div class="col-3">
            <Content />
        </div>
        <div class="col-9">
            <TextEditor />
        </div>
    </div>
</template>

<script>
import TextEditor from "@/components/textEditor/index.vue"
import Content from "@/components/textEditor/content.vue"

export default {
    data() {
        return {

        }
    },
    created() {
    },
    components: {
        TextEditor,
        Content
    },
    methods: {
    }
}
</script>

<style>
.upMenu{
    margin-bottom: 20px;
}
</style>